<template>
  <div>
    <div :key="index" v-for="(img, index) in images">
      <cropper
        ref="cropper"
        class="cropper"
        :src="img.origin"
        :stencil-props="stencilProps"
        :transitions="true"
        default-boundaries="fill"
        image-restriction="fit-area"
      />
      <div class="btns-cropper d-flex flex-column">
        <v-icon @click="flip(true, false, index)" class="pa-1 white--text">mdi-flip-vertical</v-icon>
        <v-icon @click="flip(false, true, index)" class="pa-1 white--text">mdi-flip-horizontal</v-icon>
        <v-icon @click="rotate(-90, index)" class="pa-1 white--text">mdi-rotate-left</v-icon>
        <v-icon @click="rotate(90, index)" class="pa-1 white--text">mdi-rotate-right</v-icon>
        <v-icon @click="download(index, img.cropped)" class="pa-1 white--text">mdi-check</v-icon>
      </div>
      <div v-if="img.cropped" class="preview">
        <img :src="img.cropped" width="100" height="130" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    Cropper
  },
  props: {
    images: { type: Array, default: () => ([]) },
    stencilProps: { type: Object, default: () => ({ movable: true, scalable: true, aspectRatio: 3 / 4 }) }
  },
  methods: {
    flip (x, y, indexRef) {
      if (this.$refs.cropper[indexRef].customImageTransforms.rotate % 180 !== 0) this.$refs.cropper[indexRef].flip(!x, !y)
      else this.$refs.cropper[indexRef].flip(x, y)
    },
    rotate (angle, indexRef) {
      this.$refs.cropper[indexRef].rotate(angle)
    },
    download (indexRef, cropped) {
      const result = this.$refs.cropper[indexRef].getResult().canvas.toDataURL()
      this.images[indexRef].cropped = result
      if (this.$listeners?.updateFile) this.$emit('updateFile', result)
    },
    change (args) {
    }
  }
}
</script>

<style scoped>
  .btns-cropper {
    position: absolute;
    top: 25%;
    left: 25px;
    background: black;
  }

  .preview {
    position: absolute;
    z-index: 9;
    top: 23rem;
    left: 20rem;
    width: max-content;
  }
</style>

<template lang="pug">
div
  v-skeleton-loader.mx-auto.mt-5(
      v-if="isLoadingFile"
      max-width="300"
      type="card")
  div.d-flex.flex-wrap.justify-center.align-center.pt-6
    div(v-for="photo of photoArray" :key="photo.id").photoDocument-block
      div(v-if="!isHiddenActionsButton").photoDocument-actions
        v-slide-x-reverse-transition
          div(v-if="showPhotoActions")
            v-tooltip(bottom)
              template(#activator="{ on }")
                v-btn(
                  v-if="photo.id && !forbiddenDeletingTypes.includes(documentType) && checkAccess(documentType, 'deleteFile', sailorDocument, photo) && allowDelete"
                  v-on="on"
                  @click="deleteDocument(photo)"
                  color="primary"
                  fab
                  x-small
                ).mx-2
                  v-icon mdi-trash-can-outline
              span {{ $t('delete') }}
            v-tooltip(bottom)
              template(#activator="{ on }")
                v-btn(
                  v-if="checkAccess('documentManipulation') && ['jpeg', 'png', 'jpg'].includes(photo.type) && currentDocumentType.updatingFunctionName"
                  v-on="on"
                  @click="openFullSizeFile(photo)"
                  color="primary"
                  fab
                  x-small
                ).mx-2
                  v-icon mdi-pen
              span {{ $t('edit') }}
        v-btn(
          v-if="(photo.id && !forbiddenDeletingTypes.includes(documentType) && checkAccess(documentType, 'deleteFile', sailorDocument, photo) && allowDelete) || (checkAccess('documentManipulation') && ['jpeg', 'png', 'jpg'].includes(photo.type) && currentDocumentType.updatingFunctionName)"
          @click="showPhotoActions = !showPhotoActions" color="primary"
          fab
          x-small).mx-2
          v-icon mdi-dots-vertical

      a(:href="photo.url" target="_blank").d-block
        div.photoDocument-block__image
          img.image(
            v-if="['jpeg', 'png', 'jpg'].includes(photo.type)"
            :src="photo.url"
            :alt="documentType")
          embed(
            v-else
            :src="photo.url"
            width="324"
            height="324"
          ).d-block
          span(v-if="photo.isDeleted").photoDocument-text {{ $t('deleted') }}
        //p.photoDocument-name {{ photo.name.substring(photo.name.lastIndexOf('/') + 1) }}
        p(v-if="photo.name").photoDocument-name {{ $t((photo.name.split('/').pop().split('?')[0])) }}
        p(v-else-if="photo.photo").photoDocument-name {{ $t((photo.photo.split('/').pop().split('?')[0])) }}

    v-dialog(v-if="fullSizePhoto" v-model="fullSizePhotoModal" max-width="600" max-height="600" width="fit-content")
      div.position-relative
        Cropper(
          ref="cropper"
          :images="[fullSizePhoto.base64]"
          :stencilProps="{ movable: false, resizable: true }"
          @updateFile="updateFile")
        v-overlay(:value="isEditedFileSaving" color="#F9FAFB" absolute)
          v-progress-circular(size="64" color="#E4E7EC" indeterminate)

</template>

<script>
import Cropper from '@/components/modules/Cropper'
import { checkAccess } from '@/mixins/permissions'
import { dataURLtoFile, deleteConfirmation, getFilesFromData, FileToBase64, addNeedFieldForViewFiles } from '@/mixins/main'
import { mapActions, mapState } from 'vuex'
import { addNeedFieldForViewFilesV2, downloadBlob } from '../../mixins/main'

export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) },
    documentType: { type: String },
    allowDelete: { type: Boolean, default: true },
    isHiddenActionsButton: { type: Boolean, default: false }
  },
  components: {
    Cropper
  },
  data () {
    return {
      forbiddenDeletingTypes: ['newCrewingManagers', 'newAccounts', 'crewingManagerStatements',
        'serviceRecordBookLineShortInfo'],
      allDocumentTypes: [
        { type: 'civilPassport', // CITIZEN(CIVIL) PASSPORT
          getFunctionName: 'getSailorCitizenPassports',
          photoTypeDoc: 'passport',
          updatingFunctionName: 'updateFilesCitizenPassport' },
        { type: 'sailorPassport', // SAILOR PASSPORT
          getFunctionName: 'getSailorPassport',
          photoTypeDoc: 'SeafarerPassDoc',
          updatingFunctionName: 'updateFilesSailorPassport' },
        { type: 'sailorInternationalPassport', // INTERNATIONAL PASSPORT
          getFunctionName: 'getSailorForeignPassports',
          photoTypeDoc: 'ForeignPassport',
          updatingFunctionName: 'updateFilesForeignPassportById' },
        { type: 'sailorFullNameChanges', // SAILOR FULL NAME CHANGES
          // getFunctionName: 'getFullNameChanges',
          photoTypeDoc: 'OldName',
          updatingFunctionName: 'updateFilesFullNameChanges' },
        { type: 'sailorPassportStatement', // SAILOR PASSPORT STATEMENTS
          getFunctionName: 'getSailorPassportStatementById',
          photoTypeDoc: 'StatementSailorPassport',
          updatingFunctionName: 'updateFilesSailorPassportStatements' },
        { type: 'education', // EDUCATION DOCUMENTS
          getFunctionName: 'getEducationDocumentByID',
          photoTypeDoc: 'GraduationDoc',
          updatingFunctionName: 'updateFilesEducationDocument' },
        { type: 'student', // STUDENT CARDS
          getFunctionName: 'getStudentCard',
          photoTypeDoc: 'StudentCard'
          // updatingFunctionName: 'updateFilesStudentCard'
        },
        { type: 'educationStatement', // ADVANCE TRAINING STATEMENTS
          getFunctionName: 'getGraduationStatements',
          photoTypeDoc: 'StatementAdvancedTraining',
          updatingFunctionName: 'updateFilesEducationStatements' },
        { type: 'qualification', // QUALIFICATION DOCUMENTS
          getFunctionName: 'getQualificationDocumentsByID',
          photoTypeDoc: 'QualificationDoc',
          updatingFunctionName: 'updateFilesQualificationDocuments' },
        { type: 'qualificationStatement', // QUALIFICATION STATEMENTS
          getFunctionName: 'getQualificationStatements',
          photoTypeDoc: 'StatementQualificationDoc',
          updatingFunctionName: 'updateFilesQualificationStatement' },
        { type: 'navigatorCertificates', // NAVIGATOR CERTIFICATES
          getFunctionName: 'getNavigatorCertificates',
          photoTypeDoc: 'CertificateOfNavigator',
          updatingFunctionName: 'updateFilesNavigatorCertificates' },
        { type: 'certificationStatement', // ETI CERTIFICATE STATEMENTS
          getFunctionName: 'getCertificateStatements',
          photoTypeDoc: 'StatementETI',
          updatingFunctionName: 'updateFilesCertificateStatement' },
        { type: 'serviceRecordBook', // SERVICE RECORD BOOKS
          getFunctionName: 'getRecordBookByID',
          photoTypeDoc: 'RecordBookDoc',
          updatingFunctionName: 'updateFilesRecordBook' },
        { type: 'serviceRecordBookLine', // LINES IN SERVICE RECORD BOOK
          getFunctionName: 'getRecordBookLineEntryByID',
          photoTypeDoc: 'ExperienceDoc',
          updatingFunctionName: 'updateFilesRecordBookLineEntry' },
        { type: 'experience', // EXPERIENCE REFERENCES
          getFunctionName: 'getExperienceReferences',
          photoTypeDoc: 'ExperienceDoc',
          updatingFunctionName: 'updateFilesExperienceReferences' },
        { type: 'recordBookStatement', // SERVICE RECORD BOOK STATEMENTS
          getFunctionName: 'getRecordBookStatement',
          photoTypeDoc: 'StatementServiceRecord',
          updatingFunctionName: 'updateFilesRecordBookStatement' },
        { type: 'sailorSQCStatement', // SQC STATEMENTS
          getFunctionName: 'getSQCStatementByID',
          photoTypeDoc: 'StatementSqp',
          updatingFunctionName: 'updateFilesSQCStatements' },
        { type: 'sailorSQCProtocols', // SQC PROTOCOLS
          getFunctionName: 'getProtocolsSQCByID',
          photoTypeDoc: 'ProtoclsSQCDoc',
          updatingFunctionName: 'updateFilesProtocolsSQC' },
        { type: 'sailorMedical', // MEDICAL DOCUMENTS
          getFunctionName: 'getMedicalCertificateByID',
          photoTypeDoc: 'MedicalDoc',
          updatingFunctionName: 'updateFilesMedicalCertificate' },
        { type: 'medicalStatement', // MEDICAL STATEMENTS
          getFunctionName: 'getMedicalStatementByID',
          photoTypeDoc: 'StatementMedicalCertificate',
          updatingFunctionName: 'updateFilesMedicalStatement' },
        { type: 'positionStatement', // POSITION STATEMENTS
          getFunctionName: 'getPositionStatements',
          photoTypeDoc: 'PacketItem',
          updatingFunctionName: 'updateFilesPositionStatement' },
        { type: 'documentsVerificationByPhoto', // DOCUMENT VERIFICATION BY PHOTO
          getFunctionName: 'getVerificationDocumentPhotoDocument',
          updatingFunctionName: 'updateFilesVerificationDocumentPhotoDocument' }
      ],
      photoArray: [],
      fullSizePhoto: null,
      photoTypeDoc: null,
      getFunctionName: null,
      updatingFunctionName: null,
      showPhotoActions: false,
      fullSizePhotoModal: false,
      isEditedFileSaving: false,
      checkAccess,
      isLoadingFile: false
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId
    }),
    currentDocumentType () {
      return this.allDocumentTypes.find(item => item.type === this.documentType) || {}
    }
  },
  beforeMount () {
    if (this.documentType === 'sailorDigitalDocument') {
      this.photoArray = addNeedFieldForViewFiles(this.sailorDocument.files || this.sailorDocument.photo)
      return
    } else if (this.documentType === 'accountManagement') this.photoArray = getFilesFromData(this.sailorDocument.userprofile.order_scan)
    if (['nostrification', 'powersOfAttorney'].includes(this.documentType)) {
      this.getFileServer()
      this.getFile(this.sailorDocument.files[0].file).then(resp => {
        if (this.sailorDocument.files[0].type !== 'pdf' && resp.type === 'application/pdf') {
          // downloadBlob(resp.slice(0, resp.size, 'image/jpeg'), 'image', this.sailorDocument.files[0].type)
        }
      })
      this.photoArray = this.sailorDocument.files
    } else if (this.documentType === 'education') this.photoArray = addNeedFieldForViewFiles(this.sailorDocument.files || this.sailorDocument.photo)
    else if (this.sailorDocument.files) this.photoArray = getFilesFromData(this.sailorDocument.files)
    else if (this.documentType === 'sailorPhoto') this.getAllFile(this.sailorDocument.photos)
    else this.photoArray = getFilesFromData(this.sailorDocument.photo)
    this.getFunctionName = this.currentDocumentType.getFunctionName
    this.photoTypeDoc = this.currentDocumentType.photoTypeDoc
    this.updatingFunctionName = this.currentDocumentType.updatingFunctionName
    if (this.documentType === 'qualification' && this.sailorDocument.type_document === 16) {
      this.getFunctionName = 'getQualificationDiplomaProofByID'
      this.updatingFunctionName = 'updateFilesQualificationDiplomaProof'
      this.photoTypeDoc = 'ProofOfWorkDiploma'
    }
  },
  watch: {
    photoArray (val) {
      if (val?.length > 0) this.isLoadingFile = false
      else setTimeout(() => (this.isLoadingFile = false), 3000)
    },
    sailorDocument: {
      handler (val) {
        if (this.documentType === 'sailorPhoto') this.getAllFile(val.photos)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['deleteFileNostrificationStatement', 'getFile']),
    async getFileServer () {
      let files = await addNeedFieldForViewFilesV2(this.sailorDocument.files)
      if (!files) return
      files.forEach(file => {
        this.getFile(file.url).then(resp => {
          if (file.type !== 'pdf' && resp.type === 'application/pdf') {
            downloadBlob(resp.slice(0, resp.size, 'image/jpeg'), 'image', file.type)
          }
        })
      })
    },
    openFullSizeFile (photo) {
      photo.origin = photo.url
      this.fullSizePhoto = photo
      this.fullSizePhotoModal = true
    },
    async getAllFile (files) {
      this.photoArray = await Promise.all(files.map(async (el) => {
        const response = await FileToBase64(el)
        return response
      }))
    },
    /** Delete documents */
    deleteDocument (file) {
      deleteConfirmation().then(async confirmation => {
        if (confirmation) {
          const body = { type_document: this.photoTypeDoc, id_document: this.sailorDocument.id }
          if (['nostrification'].includes(this.documentType)) {
            await this.deleteFileNostrificationStatement({ ...this.$route.params, fileId: file?.id })
            return
          }
          this.$api.delete(`api/v1/sailor/photo_uploader/${file.id}/`, { body }).then(response => {
            if ([200, 204].includes(response?.code)) {
              this.$notification.success('deletedDocument')

              let id = this.id
              if (['qualification', 'sailorSQCStatement', 'sailorSQCProtocols', 'sailorMedical',
                'medicalStatement', 'serviceRecordBook', 'serviceRecordBookLine'].includes(this.documentType)) id = this.sailorDocument.id
              else if (['education'].includes(this.documentType)) id = { sailorId: this.$route.params.id, id: this.$route.params.documentID }
              else if (['documentsVerificationByPhoto'].includes(this.documentType)) id = this.$route.params.documentID

              if (this.documentType === 'serviceRecordBookLine') this.$store.dispatch(this.getFunctionName, { id, service_record: this.sailorDocument.service_record })
              else if (['sailorPassportStatement'].includes(this.documentType)) this.$store.dispatch(this.getFunctionName, { ...this.$route.params })
              else this.$store.dispatch(this.getFunctionName, id)
            } else this.$notification.error('cantDeleteDoc')
          })
        }
      })
    },

    updateFile (file) {
      if (!file) return false
      this.isEditedFileSaving = true

      const formData = new FormData()
      formData.append('new_file', dataURLtoFile(file, 'origin'))

      const body = { document_id: this.sailorDocument.id, photo_id: this.fullSizePhoto.id, body: formData }
      if (this.documentType === 'serviceRecordBookLine') body.service_record = this.sailorDocument.service_record

      this.$store.dispatch(this.updatingFunctionName, body).finally(() => {
        this.isEditedFileSaving = false
        this.fullSizePhotoModal = false
        this.fullSizePhoto = null
      })
    }
  }
}
</script>

<style lang="sass">
  .photoDocument
    &-block
      position: relative
      max-width: 324px
      margin: 0 32px
      &__image
        position: relative
        max-width: 324px
        max-height: 324px
        text-align: center
        img
          max-width: 324px
          max-height: 324px
    &-actions
      display: flex
      z-index: 2
      position: absolute
      right: 0
      top: 10px
      cursor: pointer
    &-name
      width: 324px
      text-align: center
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    &-text
      position: absolute
      bottom: 0
      right: 0
      background-color: #000000
      color: #FFFFFF
      font-weight: bold
      text-transform: uppercase
      padding: 4px 8px
    &-profile
      min-width: 250px
      max-width: 250px
      height: 100%
  embed
    html
      body
        img
          width: 324px
</style>
